import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { isFetching, getNotes, insertNote } from './notes.actions';

export interface NoteEntity {
  nid: number; // note id
  mid: number; // member id
  uid: number; // user / employee id
  note: string;
  ts: number;
}

export interface State extends EntityState<NoteEntity> {
  // add additional fields here
}

export const adapter: EntityAdapter<NoteEntity> = createEntityAdapter<
  NoteEntity
>({
  selectId: (note: NoteEntity) => note.nid,
});

export const initialState: State = adapter.getInitialState({});

const reducer = createReducer(
  initialState,
  on(isFetching, (state, { value }) => ({ ...state, isFetching: value })),
  on(getNotes, (state, { notes }) => adapter.upsertMany(notes, state)),
  on(insertNote, (state, { memberId, userId, note }) => {
    const maxId = (state.ids as number[]).reduce((root, id) => {
      return root > id ? root : id;
    }, 0);
    const entity: NoteEntity = {
      nid: maxId + 1, // @todo from backend
      mid: memberId,
      uid: userId,
      note,
      ts: Date.now(),
    };
    return adapter.addOne(entity, state);
  })
);

export function notesReducer(state, action) {
  return reducer(state, action);
}
