import { createAction, props } from '@ngrx/store';
import { NoteEntity } from './notes.reducer';

export const isFetching = createAction(
  '[Notes] Fetching',
  props<{ value: boolean }>()
);

export const getNotes = createAction(
  '[Notes] Get',
  props<{ notes: NoteEntity[] }>()
);

export const insertNote = createAction(
  '[Notes] Add',
  props<{ memberId: number; userId: number; note: string }>()
);
