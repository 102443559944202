import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  getMembers,
  insertMember,
  isFetching,
  setSearchValue,
  updateMember,
} from './members.actions';

export interface MemberEntity {
  mid: number;
  email: string;
  firstname: string;
  lastname: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalcode: string;
  cellphone: string;
  homephone: string;
  workphone: string;
  membersince: number;
  properties: any;
  cantext: boolean;
  canmail: boolean;
  isactive: boolean;
  issuspended: boolean;
  status: any;
}

export interface State extends EntityState<MemberEntity> {
  // add additional fields here
  isFetching: boolean;
  searchValue: string;
}

export const adapter: EntityAdapter<MemberEntity> = createEntityAdapter<
  MemberEntity
>({
  selectId: (member: MemberEntity) => member.mid,
});

export const initialState: State = adapter.getInitialState({
  isFetching: false,
  searchValue: '',
});

const reducer = createReducer(
  initialState,
  on(isFetching, (state, { value }) => ({ ...state, isFetching: value })),
  on(getMembers, (state, { members }) => adapter.upsertMany(members, state)),
  on(updateMember, (state, { member }) =>
    adapter.updateOne({ id: member.mid, changes: member }, state)
  ),
  on(insertMember, (state, { member }) => adapter.addOne(member, state)),
  on(setSearchValue, (state, { searchValue }) => ({
    ...state,
    searchValue,
  }))
);

export function membersReducer(state, action) {
  return reducer(state, action);
}
