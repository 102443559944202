import { MemberEntity } from 'src/app/store/members/members.reducer';

export const MEMBERS: MemberEntity[] = [
  {
    mid: 1,
    email: 'bobthorn@yahoo.com',
    firstname: 'bob',
    lastname: 'thorn',
    address1: '1234 SW 120 ST',
    address2: 'Unit 11',
    city: 'Los Angeles',
    state: 'CA',
    country: 'USA',
    postalcode: '123456',
    cellphone: '1234567890',
    homephone: '1234567890',
    workphone: '1234567890',
    membersince: 20200101,
    properties: {},
    cantext: true,
    canmail: true,
    isactive: true,
    issuspended: false,
    status: 'status here',
  },
];

export const GUESTS: MemberEntity[] = [
  {
    mid: 10,
    email: 'guest@yahoo.com',
    firstname: 'alice',
    lastname: 'guest',
    address1: '1234 SW 120 ST',
    address2: 'Unit 11',
    city: 'Los Angeles',
    state: 'CA',
    country: 'USA',
    postalcode: '123456',
    cellphone: '1234567890',
    homephone: '1234567890',
    workphone: '1234567890',
    membersince: 0,
    properties: {},
    cantext: true,
    canmail: true,
    isactive: true,
    issuspended: false,
    status: 'status here',
  },
];
