import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getNotes, isFetching, insertNote } from 'src/app/store/notes/notes.actions';
import { NOTES } from './note-mocks';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  constructor(private store: Store) {}

  async fetchNotesByMemberId(memberId: number) {
    try {
      this.store.dispatch(isFetching({ value: true }));
      // @todo replace with backend implementation
      this.store.dispatch(getNotes({ notes: NOTES }));
    } catch (err) {
      // display error toast / probably move it to middleware entirely
    } finally {
      this.store.dispatch(isFetching({ value: false }));
    }
  }

  async addNote(memberId: number, userId: number, note: string) {
    // @todo replace with backend implementation
    this.store.dispatch(insertNote({ memberId, userId, note }));
  }
}
