import { NoteEntity } from 'src/app/store/notes/notes.reducer';

export const NOTES: NoteEntity[] = [
  {
    nid: 1,
    mid: 1,
    uid: 1,
    note: 'this is a comment.',
    ts: new Date('2020/10/18').getTime(),
  },
  {
    nid: 2,
    mid: 1,
    uid: 1,
    note: 'and another comment entry a day later.',
    ts: new Date('2020/10/19').getTime(),
  },
];
