import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  getEvents,
  insertEvent,
  isFetching,
  updateEvent,
  setEventRange,
} from './events.actions';

export interface EventEntity {
  eid: number;
  name: string;
  description: string;
  startdatetime: number;
  enddatetime: number;
  maxper: number;
  maxrsvp: number;
  price: number;
  isreservation: boolean;
  isprivate: boolean;
  ismemberonly: boolean;
}

export interface State extends EntityState<EventEntity> {
  // add additional fields here
  isFetching: boolean;
  eventsRangeFrom: number;
  eventsRangeTo: number;
}

export const adapter: EntityAdapter<EventEntity> = createEntityAdapter<
  EventEntity
>({
  selectId: (event: EventEntity) => event.eid,
});

export const initialState: State = adapter.getInitialState({
  isFetching: false,
  eventsRangeFrom: 0,
  eventsRangeTo: 0,
});

const reducer = createReducer(
  initialState,
  on(isFetching, (state, { value }) => ({ ...state, isFetching: value })),
  on(getEvents, (state, { events }) => adapter.upsertMany(events, state)),
  on(updateEvent, (state, { event }) =>
    adapter.updateOne({ id: event.eid, changes: event }, state)
  ),
  on(insertEvent, (state, { event }) => adapter.addOne(event, state)),
  on(setEventRange, (state, { from, to }) => ({
    ...state,
    eventsRangeFrom: from,
    eventsRangeTo: to,
  }))
);

export function eventsReducer(state, action) {
  return reducer(state, action);
}
