import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import {
  deleteMember,
  insertMember,
  isFetching,
  getMembers,
  updateMember,
  setSearchValue,
} from 'src/app/store/members/members.actions';
import { MemberEntity } from 'src/app/store/members/members.reducer';
import { GUESTS, MEMBERS } from './member-mocks';

let searchMemberDebounceTimer;

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  constructor(private store: Store, private alertCtrl: AlertController) {}

  async fetchMembers(isGuest = false) {
    try {
      this.store.dispatch(isFetching({ value: true }));
      // @todo replace with backend implementation
      this.store.dispatch(getMembers({ members: isGuest ? GUESTS : MEMBERS }));
    } catch (err) {
      // display error toast / probably move it to middleware entirely
    } finally {
      this.store.dispatch(isFetching({ value: false }));
    }
  }

  async updateMember(member: MemberEntity) {
    try {
      this.store.dispatch(isFetching({ value: true }));
      // @todo replace with backend implementation
      this.store.dispatch(updateMember({ member }));
    } finally {
      this.store.dispatch(isFetching({ value: false }));
    }
  }

  async insertMember(member: MemberEntity) {
    try {
      this.store.dispatch(isFetching({ value: true }));
      // @todo replace with backend implementation
      this.store.dispatch(insertMember({ member }));
    } finally {
      this.store.dispatch(isFetching({ value: false }));
    }
  }

  async deleteMember(member: MemberEntity) {
    const alert = await this.alertCtrl.create({
      header: 'Delete member',
      subHeader: `${member.firstname} ${member.lastname}`,
      message: `Are you sure you want to delete this member?`,
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        { text: 'Delete', role: 'delete' },
      ],
    });
    alert.present();
    const { role } = await alert.onWillDismiss();
    // check if we should delete now?
    if (role === 'delete') {
      try {
        this.store.dispatch(isFetching({ value: true }));
        // @todo replace with backend implementation
        this.store.dispatch(deleteMember({ member }));
      } finally {
        this.store.dispatch(isFetching({ value: false }));
      }
    }
  }

  async search(searchValue: string, isGuest = false, delay = 300) {
    if (searchMemberDebounceTimer) {
      clearTimeout(searchMemberDebounceTimer);
    }
    this.store.dispatch(isFetching({ value: true }));
    this.store.dispatch(setSearchValue({ searchValue }));
    // debounce the search request
    searchMemberDebounceTimer = setTimeout(() => {
      try {
        // @todo replace with backend implementation of search
        this.store.dispatch(
          getMembers({ members: isGuest ? GUESTS : MEMBERS })
        );
      } finally {
        this.store.dispatch(isFetching({ value: false }));
      }
    }, delay);
  }
}
