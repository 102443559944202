import { createAction, props } from '@ngrx/store';
import { EventEntity } from './events.reducer';

export const isFetching = createAction(
  '[Events] Fetching',
  props<{ value: boolean }>()
);

export const getEvents = createAction(
  '[Events] Get',
  props<{ events: EventEntity[] }>()
);

export const insertEvent = createAction(
  '[Events] Insert',
  props<{ event: EventEntity }>()
);

export const updateEvent = createAction(
  '[Events] Update',
  props<{ event: EventEntity }>()
);

export const deleteEvent = createAction(
  '[Events] Delete',
  props<{ event: EventEntity }>()
);

export const setEventRange = createAction(
  '[Events] Set range',
  props<{ from: number; to: number }>()
);
