import { createAction, props } from '@ngrx/store';
import { MemberEntity } from './members.reducer';

export const isFetching = createAction(
  '[Members] Fetching',
  props<{ value: boolean }>()
);

export const getMembers = createAction(
  '[Members] Get',
  props<{ members: MemberEntity[] }>()
);

export const insertMember = createAction(
  '[Members] Insert',
  props<{ member: MemberEntity }>()
);

export const updateMember = createAction(
  '[Members] Update',
  props<{ member: MemberEntity }>()
);

export const deleteMember = createAction(
  '[Members] Delete',
  props<{ member: MemberEntity }>()
);

export const setSearchValue = createAction(
  '[Members] Set search value',
  props<{ searchValue: string }>()
);
